import axios from 'axios'

const API = 'entregas/pedidos'

const Entregas = {
    listar(id_cedis){
        return axios(`${API}/listado?cedis=${id_cedis}`)
    },
    productos(params){
        return axios(`${API}/productos`,{params:params})
    },
    indicadores(params){
        return axios(`${API}/productos/indicadores`,{params:params})
    },
    listarEntregasDelivery(id_delivery){
        return axios(`${API}/delivery?id_delivery=${id_delivery}`)
    },

}

export default Entregas
