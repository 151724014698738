<template>
    <section v-loading="loading_excel" class="productos-desconocidos">
        <div class="row mx-0 py-2 align-items-center bg-white br-t-8">
            <div class="col-auto">
                <div class="row mx-0">
                    <div class="col-auto f-18 border-bottom_1 cr-pointer"
                         :class="array_iconos.includes(1) ? 'active-check active-check-border' : ''"
                         @click="fun_indicadores(1)"
                    >
                        <i class="icon-ok-circled-outline f-18" />
                        {{ cant_check }}
                    </div>
                    <div class="col-auto f-18 border-bottom_1 cr-pointer"
                         :class="array_iconos.includes(2) ? 'active-delete active-delete-border' : ''"
                         @click="fun_indicadores(2)"
                    >
                        <i class="icon-cancel-circled-outline f-18" />
                        {{ cant_cancel }}
                    </div>
                    <div class="col-auto f-18 border-bottom_1 cr-pointer"
                         :class="array_iconos.includes(3) ? 'active-search active-search-border' : ''"
                         @click="fun_indicadores(3)"
                    >
                        <i class="icon-search" />
                        {{ cant_search }}
                    </div>
                    <div class="col-auto f-18 border-bottom_1 cr-pointer"
                         :class="array_iconos.includes(4) ? 'active-usd active-usd-border' : ''"
                         @click="fun_indicadores(4)"
                    >
                        <i class="icon-currency-usd-circle f-20" />
                        {{ cant_usd }}
                    </div>
                    <div class="col-auto f-18 border-bottom_1 cr-pointer"
                         :class="array_iconos.includes(5) ? 'active-danger active-danger-border' : ''"
                         @click="fun_indicadores(5)"
                    >
                        <i class="icon-attention-outline" />
                        {{ cant_alert }}
                    </div>
                    <div class="col-auto f-18 border-bottom_1 cr-pointer"
                         :class="array_iconos.includes(6) ? 'active-download' : ''"
                         @click="fun_indicadores(6)"
                    >
                        <i class="icon-descargar" />
                        {{ cant_download }}
                    </div>
                    <div class="col-auto f-18 border-bottom_1 cr-pointer"
                         :class="array_iconos.includes(7) ? 'active-morado' : ''"
                         @click="fun_indicadores(7)"
                    >
                        <i class="icon-shopping" />
                        {{ cant_briefcase }}
                    </div>
                </div>
            </div>
            <export-excel
            v-if="productosExcel.length && $can('botones_abastecimiento_productos_descargar')"
            class="ml-auto my-lg-2"
            :data="productosExcel"
            :fields="json_fields"
            :before-generate="startDownload"
            :before-finish="finishDownload"
            worksheet="productos"
            name="productos.xls"
            >
                <el-tooltip class="item" effect="light" placement="bottom">
                    <div slot="content" class="text-center" style="width:80px;">
                        <p>Descargar productos</p>
                    </div>
                    <div class="bg-general cr-pointer d-flex justify-center align-items-center ml-2 d-middle-center br-8 p-1">
                        <i class="icon-file-download-outline f-20 text-white" />
                    </div>
                </el-tooltip>
            </export-excel>
            <el-tooltip v-if="productos.length && $can('botones_abastecimiento_productos_icono')" class="item" effect="light" placement="bottom">
                <div slot="content" class="text-center" style="width:80px;">
                    <p>Cambiar Icono</p>
                </div>
                <div class="bg-general cr-pointer d-flex justify-center align-items-center ml-2 d-middle-center br-8 p-1" @click="cambioMasivoIcono">
                    <i class="icon-swap-horizontal-bold f-20 text-white" />
                </div>
            </el-tooltip>
            <div class="col-auto my-lg-2" :class="{ 'ml-auto': !productosExcel.length}">
                <el-input v-model="buscar" clearable size="small" :placeholder="`Buscar Producto o ${$config.vendedor}`" class="w-100 br-20" />
            </div>
        </div>
        <div class="row mx-0 h-100">
            <div class="col-12 px-0 h-100">
                <el-table
                id="cont-scroll"
                :data="productos.filter(o => !buscar || (o.tendero.toLowerCase().includes(buscar.toLowerCase()) || o.nombre_producto.toLowerCase().includes(buscar.toLowerCase()) || o.id_admin_pedido == buscar ) )"
                class="cr-pointer"
                header-row-class-name="text-general"
                row-class-name="text-general"
                stripe style="width: 100%;"
                @row-click="seleccionar_pedido"
                >
                    <el-table-column width="100">
                        <template slot-scope="{row}">
                            <div class="d-flex mx-0">
                                <div class="col-auto px-0">
                                    <div v-if="selected == row.id" class="bg-general br-12" style="width:10px;height:50px;" />
                                </div>
                                <div class="col-auto d-middle">
                                    <el-dropdown size="mini" placement="bottom" type="primary" @command="handleCommand($event,row.id)">
                                        <span class="el-dropdown-link">
                                            <i :class="`${fun_icono(row.icono)} f-20`" />
                                        </span>
                                        <el-dropdown-menu v-if="$can('botones_abastecimiento_productos_icono')" slot="dropdown" class="br-10" style="width:45px;">
                                            <el-dropdown-item :command="1" :disabled="row.icono === 1">
                                                <i class="icon-ok-circled-outline text-success f-20" />
                                            </el-dropdown-item>
                                            <el-dropdown-item :command="2" :disabled="row.icono === 2">
                                                <i class="icon-cancel-circled-outline text-danger f-20" />
                                            </el-dropdown-item>
                                            <el-dropdown-item :command="3" :disabled="row.icono === 3">
                                                <i class="icon-search text-primary f-20" />
                                            </el-dropdown-item>
                                            <el-dropdown-item :command="4" :disabled="row.icono === 4">
                                                <i class="icon-currency-usd-circle text-aqua f-20" />
                                            </el-dropdown-item>
                                            <el-dropdown-item :command="5" :disabled="row.icono === 5">
                                                <i class="icon-attention-outline text-gr-red f-20" />
                                            </el-dropdown-item>
                                            <el-dropdown-item :command="6" :disabled="row.icono === 6">
                                                <i class="icon-descargar text-general f-20" />
                                            </el-dropdown-item>
                                            <el-dropdown-item :command="7" :disabled="row.icono === 7">
                                                <i class="icon-shopping text-morado f-20" />
                                            </el-dropdown-item>
                                        </el-dropdown-menu>
                                    </el-dropdown>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column width="80">
                        <template slot-scope="{row}">
                            <div class="row mx-0">
                                <img :src="row.imagen" width="50px" height="50px" class="br-8 border bg-white cr-pointer" />
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="nombre_producto" class-name="f-600" label="Producto" width="180" />
                    <el-table-column label="Valor" class-name="text-center" width="100">
                        <template slot-scope="{row}">
                            <!-- <p>{{row}}</p> -->
                            <p>{{ convertMoney(row.unidad_final,row.idm_moneda) }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column class-name="text-center" label="Unidades" min-width="90">
                        <template slot-scope="{row}">
                            <p>{{ agregarSeparadoresNumero(row.cantidad) }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column class-name="text-center" label="Disponible" min-width="100">
                        <template slot-scope="{row}">
                            <p>{{ agregarSeparadoresNumero(row.disponible) }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column
                    prop="id_admin_pedido"
                    label="Pedido"
                    class-name="text-center f-600"
                    />
                    <el-table-column class-name="text-center" label="Entrega" min-width="100">
                        <template slot-scope="{row}">
                            <p>{{ row.entrega_fecha | helper-fecha('D MMM') }} {{ determinarHorario(row.entrega_horario) }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column
                    width="180"
                    prop="tendero"
                    :label="`${$config.vendedor}`"
                    />
                </el-table>
            </div>
        </div>
        <modal-si-no ref="modalEliminacion" titulo="Eliminar productos de manera masiva" @accion="cambiarMasivosIconos">
            <div class="row mx-0">
                <div class="col-12 text-center">
                    <el-dropdown size="mini" placement="bottom" type="primary" @command="handleCommand2($event)">
                        <span class="el-dropdown-link">
                            <i :class="`${fun_icono(icono)} f-20`" />
                        </span>
                        <el-dropdown-menu slot="dropdown" class="br-10" style="width:45px;">
                            <el-dropdown-item :command="null" :disabled="icono === null">
                                <i class="icon-sticker-emoji f-20" />
                            </el-dropdown-item>
                            <el-dropdown-item :command="1" :disabled="icono === 1">
                                <i class="icon-ok-circled-outline text-success f-20" />
                            </el-dropdown-item>
                            <el-dropdown-item :command="2" :disabled="icono === 2">
                                <i class="icon-cancel-circled-outline text-danger f-20" />
                            </el-dropdown-item>
                            <el-dropdown-item :command="3" :disabled="icono === 3">
                                <i class="icon-search text-primary f-20" />
                            </el-dropdown-item>
                            <el-dropdown-item :command="4" :disabled="icono === 4">
                                <i class="icon-currency-usd-circle text-aqua f-20" />
                            </el-dropdown-item>
                            <el-dropdown-item :command="5" :disabled="icono === 5">
                                <i class="icon-attention-outline text-gr-red f-20" />
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
                <div class="col-12 mt-2 text-general f-15 text-center">
                    Hay <b>{{ agregarSeparadoresNumero(productos.length) }}</b>  productos seleccionados, esta seguro que lo quiere cambiar
                </div>
            </div>
        </modal-si-no>
    </section>
</template>

<script>
import Entregas from '~/services/entregas/pedidos-admin'
import Pedidos from '~/services/pedidos/pedidos-admin'
import moment from 'moment'
import {mapGetters} from 'vuex'
import Vue from 'vue'
import excel from 'vue-excel-export'
Vue.use(excel)
export default {
    data(){
        return {
            buscar: '',
            icono: '',
            cant_usd: 3,
            cant_cancel: 3,
            cant_check: 4,
            cant_search: 6,
            cant_alert: 5,
            cant_download: 6,
            cant_briefcase: 7,
            array_iconos:[1,2,3,4,5,6,7],
            productos:[],
            selected:-1,
            loading_excel:false,
            json_fields: {
                'ID': 'id',
                'Producto': 'nombre_producto',
                'SKU': 'sku',
                'Barcode': 'barcode',
                'Valor': 'unidad_final',
                'Unidades': 'cantidad',
                'Disponible': 'disponible',
                'Pedido': 'id_admin_pedido',
                'Entrega': 'entrega_fecha_completa',
                'Vendedor': 'tendero',
                'Proveedor': 'proveedor',
            },
        }
    },
    computed:{
        ...mapGetters({
            id_cedis:'cedis/id_cedis',
            id_pedido: 'pedidos/pedidos_admin/id_pedido',
        }),
        productosExcel(){
            let ordenar = []
            let productos = _.cloneDeep(this.productos)
            ordenar = productos.map(el => {
                el.disponible = this.agregarSeparadoresNumero(el.disponible)
                el.cantidad = this.agregarSeparadoresNumero(el.cantidad)
                el.entrega_fecha_completa = moment(el.entrega_fecha).format('D MMM') + this.determinarHorario(el.entrega_horario)
                el.unidad_final = this.convertMoney(el.unidad_final,el.idm_moneda)
                return el
            })
            return _.orderBy(ordenar,'nombre_producto','asc')
        }
    },
    watch:{
        id_cedis(val){
            this.listar()
            this.indicadores()
            this.$store.commit('pedidos/pedidos_admin/set_id_pedido', null)
            this.selected = -1
        },
        productos(){
            this.$nextTick(()=>{
                let tabla = document.querySelector('#cont-scroll .el-table__body-wrapper');
                this.scrollInx(tabla)
            })
        }
    },
    mounted(){
        this.indicadores()
        this.listar()
    },
    methods: {
        async indicadores(){
            try {
                let params = {
                    cedis:this.id_cedis
                }
                const {data} = await Entregas.indicadores(params)
                this.cant_check = data.iconos.find(o=>o.icono == 1).value ?? 0
                this.cant_cancel = data.iconos.find(o=>o.icono == 2).value ?? 0
                this.cant_search = data.iconos.find(o=>o.icono == 3).value ?? 0
                this.cant_usd = data.iconos.find(o=>o.icono == 4).value ?? 0
                this.cant_alert = data.iconos.find(o=>o.icono == 5).value ?? 0
                this.cant_download = data.iconos.find(o=>o.icono == 6).value ?? 0
                this.cant_briefcase = data.iconos.find(o=>o.icono == 7).value ?? 0
            } catch (e){
                console.error(e);
            }
        },
        async listar(){
            try {
                let params = {
                    iconos:this.array_iconos,
                    cedis:this.id_cedis
                }
                const {data} = await Entregas.productos(params)
                this.productos = data.productos
            } catch (e){
                console.error(e);
            }
        },
        seleccionar_pedido(row){
            this.selected = row.id
            this.$store.commit('pedidos/pedidos_admin/set_id_pedido', row.id_admin_pedido)
        },
        fun_indicadores(val){
            let idx = this.array_iconos.indexOf(val)
            if(idx > -1){
                this.array_iconos.splice(idx,1)
            }else{
                this.array_iconos.push(val)
            }
            this.listar()
        },
        handleCommand(e,id){
            // console.log(e,id);
            let model = {id, icon:e}
            this.cambiar_icono(model)

        },
        handleCommand2(e){
            // console.log(e,id);
            this.icono = e

        },
        async cambiar_icono(model){
            try {
                const {data} = await Pedidos.cambiar_icono(model)
                this.indicadores()
                this.listar()
                if(this.id_pedido != null){
                    this.$store.dispatch('pedidos/pedidos_admin/pedidos_productos',this.id_pedido)
                }
            } catch (e){
                this.error_catch(e)
            }
        },
        fun_icono(icon){
            switch (icon){
            case null:
                return 'icon-sticker-emoji'
            case 1:
                return 'icon-ok-circled-outline text-success'
            case 2:
                return 'icon-cancel-circled-outline text-danger'
            case 3:
                return 'icon-search text-primary'
            case 4:
                return 'icon-currency-usd-circle text-aqua'
            case 5:
                return 'icon-attention-outline text-gr-red'
            case 6:
                return 'icon-descargar text-general'
            case 7:
                return 'icon-shopping text-morado'
            default:
                return 'icon-sticker-emoji'
            }
        },
        startDownload(){
            this.loading_excel = true
        },
        cambioMasivoIcono(){
            this.$refs.modalEliminacion.toggle()
        },
        async cambiarMasivosIconos(val){
            try {
                if (val){

                    const ids_pedidos_productos = this.productos.map(({id}) => id)

                    const model = {
                        estado_icono: this.icono,
                        ids_pedidos_productos
                    }

                    const {data} = await Pedidos.cambiarMasivosIconos(model)
                    this.indicadores()
                    this.listar()
                    this.array_iconos = [this.icono]
                    if(this.id_pedido != null){
                        this.$store.dispatch('pedidos/pedidos_admin/pedidos_productos',this.id_pedido)
                    }
                }
            } catch (e){
                this.error_catch(e)
            } finally {
                this.$refs.modalEliminacion.toggle()
            }
        },
        finishDownload(){
            this.loading_excel = false
        },
        scrollInx(element = null){
            if(!element) return
            const slider = element
            let isDown = false;
            let startX;
            let scrollLeft;

            slider.addEventListener('mousedown', (e) => {
                isDown = true;
                slider.classList.add('active');
                startX = e.pageX - slider.offsetLeft;
                scrollLeft = slider.scrollLeft;

            });
            slider.addEventListener('mouseleave', () => {
                isDown = false;
                slider.classList.remove('active');

            });
            slider.addEventListener('mouseup', () => {
                isDown = false;
                slider.classList.remove('active');
            });
            slider.addEventListener('mousemove', (e) => {
                if(!isDown) return;
                e.preventDefault();
                const x = e.pageX - slider.offsetLeft;
                const walk = (x - startX) * 3; //scroll-fast
                slider.scrollLeft = scrollLeft - walk;
                //console.log(walk);
            });
        },
        determinarHorario(entrega_horario){
            if (parseInt(entrega_horario) < 12){
                let hora_format = moment(entrega_horario, 'hh:mm:ss');
                return moment(hora_format).format('h:mm a');
            }
            if (parseInt(entrega_horario) >= 12){
                let hora_format = moment(entrega_horario, 'hh:mm:ss');
                return moment(hora_format).format('h:mm a');
            }
        },
    }
}
</script>
<style lang="scss" scoped >
.border-bottom_1{ border-bottom: 3px solid #DBDBDB;color: #DBDBDB}
.active-usd{ color: #03D6BC;}
.active-usd-border{ border-bottom: 3px solid #03D6BC; }
.active-delete{ color: #FF2525;  }
.active-delete-border{ border-bottom: 3px solid #FF2525; }
.active-search{
    background: -webkit-linear-gradient(90deg, #0D4DFF, #03D6BC 90%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

}
.active-search-border{ border-bottom:3px solid #0D4DFF; }
.active-danger{
    background: -webkit-linear-gradient(90deg, #FF2525, #FF9D32 90%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.active-danger-border{ border-bottom: 3px solid #FF2525; }

.active-check{ color: #28D07B;}
.active-check-border{ border-bottom: 3px solid #28D07B; }

.inactive{ color: #DBDBDB;border-bottom: 3px solid #DBDBDB !important; }
.br-12{ border-radius: 12px !important; }
.tab-active{ color: #5D5D5D; border-bottom: 2px solid #FF612B !important; }
.tab{color: #5D5D5D; }
a{
    text-decoration:none;
}
.text-morado{
    color: #CC05A4
}
.active-download{
    color: #000000;
    border-bottom: 3px solid #000000;
}
.active-morado{
    color: #CC05A4;
    border-bottom: 3px solid #CC05A4;
}
</style>
